import React from "react";

import Image from "./Image";

const ImageText = ({ loading, data }) => {
  if (loading || !data) {
    return null;
  }
  const {
    image,
    text,
    imagePosition,
    backgroundColor,
    textColor,
    fontSize,
    isCategoryLink,
    link,
  } = data;

  return (
    <div className={"image-text " + imagePosition}>
      <div
        className="image-text__text-container"
        style={{
          backgroundColor: backgroundColor,
          color: textColor,
          fontSize: fontSize ? fontSize : "4rem",
        }}
      >
        <div
          className="image-text__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>

      <div className="image-text__image-container">
        <Image image={image} isCategoryLink={isCategoryLink} link={link} />
      </div>
    </div>
  );
};

export default ImageText;
